import React, { FC } from 'react';

import FormHelperTextMaterial from '@mui/material/FormHelperText';
import classNames from 'classnames/bind';

import { FaIcon } from '@components/FaIcon';

import styles from './FormDescriptionText.module.css';

export type Props = {
  descriptionText: string;
};

const cn = classNames.bind(styles);

export const FormDescriptionText: FC<Props> = ({ descriptionText }) => (
  <FormHelperTextMaterial className={cn('form-description-text__content')}>
    <FaIcon iconName="question-circle" />

    {descriptionText}
  </FormHelperTextMaterial>
);
