import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
  ChangeEvent,
  ReactElement,
  useRef,
  KeyboardEventHandler,
  KeyboardEvent,
  useEffect,
} from 'react';

import { ListSubheader, Box, Checkbox, ListItemText, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import SelectMaterial, { SelectProps as SelectPropsMaterial } from '@mui/material/Select';
import classNames from 'classnames/bind';

import { FaIcon } from '@components/FaIcon';
import { FormDescriptionText } from '@components/FormDescriptionText';
import { FormHelperText } from '@components/FormHelperText';
import { InputLabel } from '@components/InputLabel';
import { MenuItem, MenuItemProps } from '@components/Menu';
import { SearchField } from '@components/SearchField';
import { SvgIcon } from '@components/SvgIcon';
import { InputAdornment } from '@components/TextField';

import { locale } from './locale';

import styles from './Select.module.css';

const cn = classNames.bind(styles);
const STARTSWITH_CHARS_SEARCH_AMOUNT = 1;
const INCLUDES_CHARS_SEARCH_AMOUNT = 3;

export type OptionType = { id: string | number; name: string | number };
export type SearchCondition = 'startsWith' | 'includes';

export type SelectProps<CustomOptionType = OptionType> = {
  className?: string;
  selectClassName?: string | string[];
  labelClassName?: string | string[];
  placeholderClassName?: string;
  menuClassName?: string | string[];
  selectRootClassName?: string;
  selectDisabledClassName?: string;
  helperText?: Nullable<string>;
  idKey?: string;
  loading?: boolean;
  nameKey?: string;
  noneOption?: OptionType;
  clearValue?: boolean;
  options?: Array<CustomOptionType>;
  selectOption?: boolean;
  hideCurrentOption?: boolean;
  chevronIcon?: ReactElement;
  renderOptionValue?: (option: CustomOptionType) => string | ReactNode;
  renderOption?: (option: CustomOptionType, i?: number) => string | ReactNode;
  renderOptions?: (options: CustomOptionType[]) => string | ReactNode;
  ariaLabel?: string;
  ariaDescribedby?: string;
  labelId: string;
  itemRole?: MenuItemProps['role'];
  menuItemContentClassName?: MenuItemProps['contentClassName'];
  optionComponent?: 'li' | 'div' | null;
  hiddenLabel?: boolean;
  autoComplete?: string;
  descriptionText?: string;
  isSearchable?: boolean;
  withCheckboxList?: boolean;
  searchFieldPlaceholder?: string;
  searchCondition?: SearchCondition;
} & Pick<
  SelectPropsMaterial,
  | 'disabled'
  | 'onChange'
  | 'value'
  | 'label'
  | 'error'
  | 'variant'
  | 'fullWidth'
  | 'placeholder'
  | 'required'
  | 'name'
  | 'multiple'
  | 'renderValue'
  | 'onOpen'
  | 'onClose'
  | 'onFocus'
  | 'MenuProps'
  | 'open'
  | 'disableUnderline'
  | 'SelectDisplayProps'
  | 'inputRef'
>;

export function Select<CustomOptionType = OptionType>({
  className,
  selectClassName,
  labelClassName,
  MenuProps = {},
  menuClassName,
  selectRootClassName,
  chevronIcon,
  selectDisabledClassName,
  disabled = false,
  hideCurrentOption = false,
  error = false,
  fullWidth,
  helperText = '',
  idKey = 'id',
  label,
  loading = false,
  multiple = false,
  name,
  nameKey = 'name',
  noneOption,
  onChange,
  options = [],
  placeholder = '',
  required = false,
  selectOption = false,
  value = multiple ? [] : '',
  variant = 'standard',
  renderValue,
  renderOptionValue,
  renderOption,
  renderOptions,
  onFocus,
  onOpen,
  onClose,
  clearValue,
  open,
  labelId,
  disableUnderline,
  ariaLabel,
  ariaDescribedby = undefined,
  SelectDisplayProps,
  inputRef,
  itemRole,
  optionComponent,
  hiddenLabel,
  autoComplete,
  placeholderClassName,
  menuItemContentClassName,
  descriptionText,
  isSearchable = false,
  searchFieldPlaceholder,
  withCheckboxList = false,
  searchCondition = 'includes',
}: SelectProps<CustomOptionType>) {
  const defaultValue = useMemo(() => (multiple ? [] : ''), [multiple]);
  const defaultSelectRef = useRef<HTMLInputElement | null>(null);
  const ref = inputRef;
  const firstSearchItemRef = useRef<HTMLLIElement | null>(null);

  const [focused, setFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const loadingIcon = useCallback(
    (props: { className?: string }) => (
      <SvgIcon icon="loading" className={cn('select__icon', 'select__loading-icon', props.className)} />
    ),
    [],
  );

  const handleClearSearch = () => setSearchValue('');

  const chevronDownIcon = useCallback(
    (props: { className?: string }) =>
      chevronIcon || <FaIcon iconName="chevron-down" className={cn('select__icon', props.className)} size="small" />,
    [chevronIcon],
  );

  const handleOpen: SelectPropsMaterial['onOpen'] = (event) => {
    onOpen?.(event);
    setFocused(true);
  };

  const handleClose: SelectPropsMaterial['onClose'] = (event) => {
    onClose?.(event);
    handleClearSearch();
    setFocused(false);
  };

  const getId = useCallback(<T,>(item: T): unknown => (item as Nullable<UnknownObject>)?.[idKey], [idKey]);
  const getName = useCallback(<T,>(item: T): unknown => (item as Nullable<UnknownObject>)?.[nameKey], [nameKey]);

  const customRenderValue = useCallback(
    (value: unknown): ReactNode => {
      if (
        (placeholder && [null, ''].includes(value as string | null)) ||
        (placeholder && withCheckboxList && Array.isArray(value) && !value?.length)
      ) {
        return <span className={cn(placeholderClassName)}>{placeholder}</span>;
      }

      if (selectOption && options?.length && typeof value === 'object') {
        return getName(options.find((option) => getId(option) === getId(value))) as ReactNode;
      }

      if (Array.isArray(value) && withCheckboxList && options?.length) {
        const selectedOption = options
          .filter((option) => value.some((it) => it === getId(option)))
          .map((option) => getName(option));

        return selectedOption.join(', ');
      }

      return (getName(options.find((option) => getId(option) === value)) || value) as ReactNode;
    },
    [getName, options, placeholder, getId, selectOption, placeholderClassName, withCheckboxList],
  );

  const selectMaterialValue: unknown = useMemo(() => {
    if (Array.isArray(value)) {
      return value;
    }

    if (!value) {
      return value;
    }

    if (selectOption && options?.length && idKey) {
      if (typeof value === 'object') {
        return options.find((option) => getId(option) === getId(value)) ?? defaultValue;
      }

      return options.find((option) => getId(option) === value) ?? defaultValue;
    }

    if (typeof value === 'object') {
      return getName(value);
    }

    return value;
  }, [selectOption, options, value, getName, getId, idKey, defaultValue]);

  const getOptionValue = useCallback(
    (option: CustomOptionType) => {
      if (renderOptionValue) {
        return renderOptionValue(option);
      }

      return nameKey ? getName(option) : option;
    },
    [renderOptionValue, nameKey, getName],
  );

  const handleClear = useCallback(() => {
    const event = {
      target: { value: '' },
    } as ChangeEvent<HTMLInputElement>;

    onChange?.(event, '');
  }, [onChange]);

  const filteredOptions = useMemo(() => {
    if (hideCurrentOption) {
      return options.filter((option) =>
        selectOption && typeof value === 'object' ? getId(option) !== getId(value) : getId(option) !== value,
      );
    }

    return options;
  }, [hideCurrentOption, options, value, getId, selectOption]);

  const filteredBySearchOptions = useMemo(() => {
    const isIncludesFilterCondition = searchCondition === 'includes';
    if (
      searchValue.length >= (isIncludesFilterCondition ? INCLUDES_CHARS_SEARCH_AMOUNT : STARTSWITH_CHARS_SEARCH_AMOUNT)
    ) {
      const filterSearch = (value: string, searchValue: string) => {
        if (isIncludesFilterCondition) {
          return value.toUpperCase().includes(searchValue.toUpperCase());
        }

        return value.toUpperCase().startsWith(searchValue.toUpperCase());
      };

      return filteredOptions
        .filter(
          (option) =>
            filterSearch(getOptionValue(option) as string, searchValue) ||
            filterSearch(getName(option) as string, searchValue),
        )
        .sort((a, b) => {
          const nameA = (getOptionValue(a) as string).toUpperCase();
          const nameB = (getOptionValue(b) as string).toUpperCase();

          return nameA.localeCompare(nameB);
        });
    }

    return filteredOptions;
  }, [filteredOptions, searchValue, searchCondition, getOptionValue, getName]);

  const ariaDescId = helperText ? `${labelId}_description` : ariaDescribedby;
  const searchField = document?.getElementById(`select__search-field-${labelId}`);

  useEffect(() => {
    if (filteredBySearchOptions.length === 1) {
      firstSearchItemRef?.current?.focus();
    }
  }, [filteredBySearchOptions]);

  const handleSearchKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
    event.stopPropagation();
    if (event.key === 'ArrowDown') {
      firstSearchItemRef?.current?.focus();
    }
  }, []);

  const handleMenuItemKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>, index: number) => {
      if ((index === 0 && event.key === 'ArrowUp') || event.key === 'Backspace') {
        searchField?.focus();
      }
    },
    [searchField],
  );

  return (
    <FormControl
      variant={variant}
      data-testid="select"
      disabled={disabled || loading}
      fullWidth={fullWidth}
      className={cn('select', className)}
      classes={{
        root: cn('select__control-root', {
          'select__control-root--outlined': variant === 'outlined',
          'select__control-root--outlined-with-label': variant === 'outlined' && label,
        }),
      }}
    >
      {label && (
        <InputLabel
          className={cn(Array.isArray(labelClassName) ? labelClassName.slice() : labelClassName)}
          required={required}
          htmlFor={`input_${labelId}`}
          id={labelId}
          error={error}
        >
          {label}
        </InputLabel>
      )}
      <SelectMaterial
        id={`select-id_${labelId}`}
        autoComplete={autoComplete}
        inputRef={(node) => {
          if (typeof ref === 'function') {
            ref(value);
          }
          if (ref && 'current' in ref) {
            (ref as React.MutableRefObject<Pick<SelectPropsMaterial, 'inputRef'>>).current = node;
          }

          defaultSelectRef.current = node;
        }}
        className={cn('select__input', selectClassName, {
          'select--focused': focused,
          'select--outlined': variant === 'outlined',
        })}
        classes={{
          select: cn('select__root', selectRootClassName),
          outlined: cn('select__input-outlined'),
          disabled: selectDisabledClassName,
        }}
        disabled={disabled || loading}
        error={error}
        open={open}
        fullWidth={fullWidth}
        IconComponent={loading ? loadingIcon : chevronDownIcon}
        inputProps={{
          readOnly: loading,
          id: `input_${labelId}`,
        }}
        aria-describedby={ariaDescId}
        aria-label={ariaLabel}
        multiple={multiple}
        name={name}
        labelId={!hiddenLabel ? labelId : undefined}
        onChange={onChange}
        displayEmpty
        placeholder={placeholder}
        value={selectMaterialValue}
        variant={variant}
        disableUnderline={disableUnderline}
        aria-labelledby={`select-id_${labelId}`}
        MenuProps={{
          ...MenuProps,
          ...(isSearchable && { autoFocus: false }),
          classes: {
            paper: cn('select__menu', menuClassName, {
              [`select__menu--${variant}`]: variant,
            }),
          },
          /** Props are added to maintain Search Field changes
           * if no Search Field - default behaviour is applied
           */
          ...(isSearchable && {
            PaperProps: {
              style: {
                maxHeight: `${Math.round(window.innerHeight - ((defaultSelectRef.current as unknown as { node: HTMLInputElement })?.node?.getBoundingClientRect()?.bottom ?? 0)) - 20}px`,
              },
            },
          }),
        }}
        endAdornment={
          clearValue && !disabled ? (
            <InputAdornment position="end" className={cn('select__clear-button')}>
              <SvgIcon icon="close" fontSize="small" onClick={handleClear} inheritViewBox />
            </InputAdornment>
          ) : undefined
        }
        renderValue={renderValue || customRenderValue}
        onOpen={handleOpen}
        onFocus={onFocus}
        onClose={handleClose}
        SelectDisplayProps={SelectDisplayProps}
      >
        {noneOption && (
          <MenuItem
            role={itemRole}
            size="small"
            value={(selectOption || !idKey ? noneOption : getId(noneOption)) as string}
          >
            {(nameKey ? getName(noneOption) : noneOption) as ReactNode}
          </MenuItem>
        )}

        {isSearchable && (
          <ListSubheader className={cn('search-container')}>
            <SearchField
              id={`select__search-field-${labelId}`}
              autoFocus
              leftIcon
              onClear={handleClearSearch}
              value={searchValue}
              onSearch={(value) => {
                setSearchValue(value.target.value);
              }}
              onKeyDown={handleSearchKeyDown}
              placeholder={searchFieldPlaceholder}
            />
          </ListSubheader>
        )}

        {filteredBySearchOptions.length === 0 && isSearchable && (
          <ListSubheader className={cn('empty-results')}>{locale.emptyResults}</ListSubheader>
        )}

        {renderOptions?.(options) ??
          filteredBySearchOptions?.map(
            (option: CustomOptionType, i) =>
              renderOption?.(option, i) ?? (
                <MenuItem
                  role={itemRole}
                  size="small"
                  value={(selectOption || !idKey ? option : getId(option)) as string}
                  key={(idKey ? getId(option) : option) as string}
                  component={optionComponent}
                  contentClassName={menuItemContentClassName}
                  onKeyDown={isSearchable ? (el) => handleMenuItemKeyDown(el, i) : undefined}
                  itemRef={
                    isSearchable && i === 0
                      ? (el) => {
                          firstSearchItemRef.current = el;
                        }
                      : undefined
                  }
                >
                  {withCheckboxList ? (
                    <Box className={cn('checkbox-wrapper')}>
                      <Checkbox
                        disableRipple
                        name={getName(option) as string}
                        value={getName(option) as string}
                        checked={Array.isArray(value) && value.some((it) => it === getId(option))}
                      />
                      <ListItemText
                        primary={<Typography variant="body2">{getOptionValue(option) as string}</Typography>}
                      />
                    </Box>
                  ) : (
                    (getOptionValue(option) as ReactNode)
                  )}
                </MenuItem>
              ),
          )}
      </SelectMaterial>

      {helperText && <FormHelperText error={error} helperText={helperText} id={ariaDescId} />}

      {descriptionText && <FormDescriptionText descriptionText={descriptionText} />}
    </FormControl>
  );
}
