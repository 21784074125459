/* eslint-disable camelcase */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import isEqual from 'lodash.isequal';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { AppointmentTypeEnum, CalledFromEnum, FilterNames } from '../constants';
import {
  AppointmentItemType,
  ClinicAddressAndNameType,
  OpenAppointmentsType,
  SchedulableStateType,
  VisitReasonCategoryType,
  ConfirmAppointmentResultType,
  VisitReasonsType,
  ClinicAppointment,
} from '../types';
import {
  cancelAppointment,
  rescheduleAppointment,
  checkDuplicateAppointments,
  confirmAndScheduleAppointment,
  getAnywhereNumber,
  getPastList,
  getSchedulableStates,
  getUpcomingList,
  getVisitReasonCategories,
  searchOpenAppointments,
  abandonAppointment,
  checkVirtualProviders,
  getCustomTile,
  getCommonVisitReasons,
} from './actions';
import {
  ConfirmationDataType,
  SearchOpenAppointmentsMetaType,
  ConfirmAppointmentResultMetaType,
  CancelAppointmentPayloadType,
  AppointmentsState,
  FilterType,
} from './types';
import {
  mergeOpenAppointments,
  setOpenAppointmentsLoadedDates,
  appointmentResultHasError,
  removeAppointment,
  getDefaultFilter,
} from './utils';

const initialState: AppointmentsState = {
  appointments: {
    [AppointmentTypeEnum.Past]: [],
    [AppointmentTypeEnum.Upcoming]: [],
  },
  appointmentsStatus: {
    [AppointmentTypeEnum.Past]: StatusEnum.Uninitialized,
    [AppointmentTypeEnum.Upcoming]: StatusEnum.Uninitialized,
  },

  visitReasonCategories: [],
  visitReasonCategoriesStatus: StatusEnum.Uninitialized,

  commonVisitReasons: [],
  commonVisitReasonsStatus: StatusEnum.Uninitialized,

  schedulableStates: [],
  schedulableStatesStatus: StatusEnum.Uninitialized,

  scheduleError: null,

  openAppointments: {} as OpenAppointmentsType,
  openAppointmentsStatus: StatusEnum.Uninitialized,
  openAppointmentsLoadedDates: [],

  address: undefined,
  provider: undefined,
  sidebarMapOpen: {},
  sidebarMapFocusReturned: {},
  anywhereNumber: '',

  duplicateAppointments: false,
  duplicateAppointmentsStatus: StatusEnum.Uninitialized,

  confirmAndScheduleAppointmentStatus: StatusEnum.Uninitialized,
  cancelAppointmentStatus: StatusEnum.Uninitialized,
  rescheduleAppointmentStatus: StatusEnum.Uninitialized,
  getCustomTileStatus: StatusEnum.Uninitialized,
  customTile: null,

  filter: {
    currentDate: '',
    endDate: '',
    startDate: '',
    visitType: null,
    provider: null,
    network: [],
    visitState: '',
    inPersonVisitState: '',
    healthCenter: [],
    soonestAvailability: null,
    timeZone: null,
    defaultProviderId: null,
    appointmentTypeId: null,
    appointmentStartDate: null,
    appointmentId: null,
    providerId: null,
    rescheduleProviderId: null,
    providerSearchValue: null,
    nextVisitReason: null,
    nextVisitReasonCategory: null,
  },

  confirmationData: null,

  appointmentForReschedule: null,

  abandonAppointmentStatus: StatusEnum.Uninitialized,
  abandonmentModalOpen: false,

  bhReasonModalOpen: false,
  timezoneModalOpen: false,

  isVirtualProvidersAvailable: false,
  isVirtualProvidersAvailableStatus: StatusEnum.Uninitialized,
  isVirtualProvidersAvailableParams: null,

  calendarStartDate: null,
  cancelledAppointment: null,

  searchCount: 0,
  filterAppointmentsByUserId: 0,
};

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setScheduleError(state, action: PayloadAction<Nullable<string>>) {
      state.scheduleError = action.payload;
    },

    setCalendarStartDate(state, action: PayloadAction<string | null>) {
      state.calendarStartDate = action.payload;
    },

    setFilter(state, action: PayloadAction<Partial<FilterType>>) {
      state.filter = {
        ...state.filter,
        ...(action.payload as AppointmentsState['filter']),
      };
      state.address = undefined;
    },

    clearOpenAppointmentsStatus(state) {
      state.openAppointmentsStatus = StatusEnum.Uninitialized;
    },

    cancelOpenAppointments(state) {
      state.openAppointmentsStatus = StatusEnum.Aborted;
      state.openAppointments = {} as OpenAppointmentsType;
    },

    clearOpenAppointments(state) {
      state.openAppointments = {} as OpenAppointmentsType;
    },

    clearOpenAppointmentsLoadedDates(state) {
      state.openAppointmentsLoadedDates = [];
    },

    setAddress(state, action: PayloadAction<ClinicAddressAndNameType | null>) {
      state.address = action.payload;
    },

    setProvider(state, action: PayloadAction<Nullable<string>>) {
      state.provider = action.payload;
    },

    setSidebarMapOpen(
      state,
      action: PayloadAction<{ id?: number; provider?: Nullable<string | number>; mapVisible: boolean }>,
    ) {
      state.sidebarMapOpen = { [`${action.payload.id}-${action.payload.provider}`]: action.payload.mapVisible };
      state.sidebarMapFocusReturned = { [`${action.payload.id}-${action.payload.provider}`]: false };
    },

    setSidebarMapClose(
      state,
      action: PayloadAction<{ id?: number; provider?: Nullable<string | number>; focusReturned: boolean }>,
    ) {
      state.sidebarMapOpen = {};
      state.sidebarMapFocusReturned = {
        [`${action.payload.id}-${action.payload.provider}`]: action.payload.focusReturned,
      };
    },

    resetDuplicateAppointments(state) {
      state.duplicateAppointments = false;
      state.duplicateAppointmentsStatus = StatusEnum.Uninitialized;
    },

    resetVisitReasonCategories(state) {
      state.visitReasonCategories = [];
    },

    setAbandonmentOpen(state, action: PayloadAction<boolean>) {
      state.abandonmentModalOpen = action.payload;
    },

    setBhReasonOpen(state, action: PayloadAction<boolean>) {
      state.bhReasonModalOpen = action.payload;
    },

    reset(state) {
      state.duplicateAppointments = false;
      state.duplicateAppointmentsStatus = StatusEnum.Uninitialized;
      state.openAppointments = {} as OpenAppointmentsType;
      state.confirmAndScheduleAppointmentStatus = StatusEnum.Uninitialized;
      state.rescheduleAppointmentStatus = StatusEnum.Uninitialized;
      state.visitReasonCategoriesStatus = StatusEnum.Uninitialized;
      state.filter = initialState.filter;
      state.openAppointmentsLoadedDates = [];
      state.sidebarMapOpen = {};
      state.appointmentForReschedule = null;
      state.confirmationData = initialState.confirmationData;
      state.searchCount = 0;
    },

    resetScheduled(state) {
      state.confirmAndScheduleAppointmentStatus = StatusEnum.Uninitialized;
      state.rescheduleAppointmentStatus = StatusEnum.Uninitialized;
    },

    resetCancellation(state) {
      state.cancelledAppointment = initialState.cancelledAppointment;
      state.cancelAppointmentStatus = initialState.cancelAppointmentStatus;
    },

    resetRescheduling(state) {
      state.rescheduleAppointmentStatus = initialState.rescheduleAppointmentStatus;
      state.appointmentForReschedule = initialState.appointmentForReschedule;
    },
    resetChooseTimeChecks(state) {
      state.bhReasonModalOpen = initialState.bhReasonModalOpen;
      state.duplicateAppointmentsStatus = initialState.duplicateAppointmentsStatus;
      state.duplicateAppointments = initialState.duplicateAppointments;
    },

    setConfirmationData(state, action: PayloadAction<ConfirmationDataType | null>) {
      state.confirmationData = action.payload;
    },

    setReschedulingAppointment(state, action: PayloadAction<AppointmentItemType>) {
      state.appointmentForReschedule = action.payload;
    },
    setTimezoneModalOpen(state, action: PayloadAction<boolean>) {
      state.timezoneModalOpen = action.payload;
    },
    setFilterAppointmentsBy(state, action: PayloadAction<number>) {
      state.filterAppointmentsByUserId = action.payload;
    },
  },
  extraReducers: {
    [getPastList.pending.type]: (state: AppointmentsState) => {
      state.appointmentsStatus[AppointmentTypeEnum.Past] = StatusEnum.Pending;
    },
    [getPastList.fulfilled.type]: (state: AppointmentsState, action: PayloadAction<AppointmentItemType[]>) => {
      state.appointmentsStatus[AppointmentTypeEnum.Past] = StatusEnum.Fulfilled;
      state.appointments[AppointmentTypeEnum.Past] = action.payload;
    },
    [getPastList.rejected.type]: (state: AppointmentsState) => {
      state.appointmentsStatus[AppointmentTypeEnum.Past] = StatusEnum.Rejected;
    },

    [getUpcomingList.pending.type]: (state: AppointmentsState) => {
      state.appointmentsStatus[AppointmentTypeEnum.Upcoming] = StatusEnum.Pending;
    },
    [getUpcomingList.fulfilled.type]: (state: AppointmentsState, action: PayloadAction<AppointmentItemType[]>) => {
      state.appointmentsStatus[AppointmentTypeEnum.Upcoming] = StatusEnum.Fulfilled;
      state.appointments[AppointmentTypeEnum.Upcoming] = action.payload;
    },
    [getUpcomingList.rejected.type]: (state: AppointmentsState) => {
      state.appointmentsStatus[AppointmentTypeEnum.Upcoming] = StatusEnum.Rejected;
    },

    [getVisitReasonCategories.pending.type]: (state: AppointmentsState) => {
      state.visitReasonCategoriesStatus = StatusEnum.Pending;
    },
    [getVisitReasonCategories.fulfilled.type]: (
      state: AppointmentsState,
      action: PayloadAction<VisitReasonCategoryType[]>,
    ) => {
      state.visitReasonCategoriesStatus = StatusEnum.Fulfilled;
      state.visitReasonCategories = action.payload;
    },
    [getVisitReasonCategories.rejected.type]: (state: AppointmentsState) => {
      state.visitReasonCategoriesStatus = StatusEnum.Rejected;
    },

    [getCommonVisitReasons.pending.type]: (state: AppointmentsState) => {
      state.commonVisitReasonsStatus = StatusEnum.Pending;
    },
    [getCommonVisitReasons.fulfilled.type]: (state: AppointmentsState, action: PayloadAction<VisitReasonsType[]>) => {
      state.commonVisitReasonsStatus = StatusEnum.Fulfilled;
      state.commonVisitReasons = action.payload;
    },
    [getCommonVisitReasons.rejected.type]: (state: AppointmentsState) => {
      state.commonVisitReasonsStatus = StatusEnum.Rejected;
    },

    [getSchedulableStates.pending.type]: (state: AppointmentsState) => {
      state.schedulableStatesStatus = StatusEnum.Pending;
    },
    [getSchedulableStates.fulfilled.type]: (
      state: AppointmentsState,
      action: PayloadAction<SchedulableStateType[]>,
    ) => {
      state.schedulableStatesStatus = StatusEnum.Fulfilled;
      state.schedulableStates = action.payload;
    },
    [getSchedulableStates.rejected.type]: (state: AppointmentsState) => {
      state.schedulableStatesStatus = StatusEnum.Rejected;
    },

    [getAnywhereNumber.fulfilled.type]: (state: AppointmentsState, action: PayloadAction<string>) => {
      state.anywhereNumber = action.payload;
    },

    [checkDuplicateAppointments.pending.type]: (state: AppointmentsState) => {
      state.duplicateAppointmentsStatus = StatusEnum.Pending;
    },
    [checkDuplicateAppointments.fulfilled.type]: (state: AppointmentsState, action: PayloadAction<boolean>) => {
      state.duplicateAppointments = action.payload;
      state.duplicateAppointmentsStatus = StatusEnum.Fulfilled;
    },
    [checkDuplicateAppointments.rejected.type]: (state: AppointmentsState) => {
      state.duplicateAppointmentsStatus = StatusEnum.Rejected;
    },

    [searchOpenAppointments.pending.type]: (state: AppointmentsState) => {
      state.openAppointmentsStatus = StatusEnum.Pending;
    },
    [searchOpenAppointments.fulfilled.type]: (
      state: AppointmentsState,
      action: PayloadAction<OpenAppointmentsType, string, SearchOpenAppointmentsMetaType>,
    ) => {
      const { start_date, end_date, calledFrom } = action.meta.arg;
      const { openAppointments, openAppointmentsLoadedDates, filter } = current(state);

      if (calledFrom === CalledFromEnum.Search) {
        if (
          action.payload.appointments?.filter((clinic: ClinicAppointment) => !isEmpty(clinic?.appointments)).length ===
          0
        ) {
          state.searchCount += 1;
        } else {
          state.searchCount = 0;
        }
      }

      state.openAppointmentsStatus = StatusEnum.Fulfilled;
      const mergedOpenAppointments = mergeOpenAppointments(openAppointments, action.payload);
      state.openAppointments = mergedOpenAppointments;

      state.filter = getDefaultFilter({
        openAppointments: mergedOpenAppointments,
        filter,
        action,
        providers: openAppointments.providers,
      });

      state.openAppointmentsLoadedDates = setOpenAppointmentsLoadedDates(openAppointmentsLoadedDates, {
        startDate: start_date,
        endDate: end_date,
      });
    },
    [searchOpenAppointments.rejected.type]: (state: AppointmentsState, action) => {
      state.openAppointmentsStatus = StatusEnum.Rejected;
      state.appointmentForReschedule = null;

      if (action.meta.aborted) {
        state.openAppointmentsStatus = StatusEnum.Aborted;
      }
    },

    [confirmAndScheduleAppointment.pending.type]: (state: AppointmentsState) => {
      state.confirmAndScheduleAppointmentStatus = StatusEnum.Pending;
    },
    [confirmAndScheduleAppointment.fulfilled.type]: (
      state: AppointmentsState,
      action: PayloadAction<ConfirmAppointmentResultType, string, ConfirmAppointmentResultMetaType>,
    ) => {
      if (appointmentResultHasError(action.payload.result)) {
        state.scheduleError = action.payload.result;
        state.confirmAndScheduleAppointmentStatus = StatusEnum.Uninitialized;
        state.openAppointments = removeAppointment(
          state.openAppointments,
          action.meta.arg.clinic_id,
          action.meta.arg.appointment_id,
        );

        return state;
      }

      state.confirmAndScheduleAppointmentStatus = StatusEnum.Fulfilled;
    },
    [confirmAndScheduleAppointment.rejected.type]: (state: AppointmentsState, action) => {
      const errorMessage = action.payload.response.data.error;

      if (appointmentResultHasError(errorMessage)) {
        state.scheduleError = errorMessage;
        state.confirmAndScheduleAppointmentStatus = StatusEnum.Uninitialized;
        state.openAppointments = removeAppointment(
          state.openAppointments,
          action.meta.arg.clinic_id,
          action.meta.arg.appointment_id,
        );

        return state;
      }

      state.confirmAndScheduleAppointmentStatus = StatusEnum.Rejected;
    },

    [cancelAppointment.pending.type]: (state: AppointmentsState) => {
      state.cancelAppointmentStatus = StatusEnum.Pending;
    },
    [cancelAppointment.fulfilled.type]: (
      state: AppointmentsState,
      action: PayloadAction<unknown, string, Meta<CancelAppointmentPayloadType>>,
    ) => {
      state.cancelAppointmentStatus = StatusEnum.Fulfilled;
      state.appointments[AppointmentTypeEnum.Upcoming] = state.appointments[AppointmentTypeEnum.Upcoming].filter(
        ({ id }) => action.meta.arg.appointment_id !== id,
      );
      state.cancelledAppointment = action.meta.arg.appointment;
    },
    [cancelAppointment.rejected.type]: (state: AppointmentsState) => {
      state.cancelAppointmentStatus = StatusEnum.Rejected;
    },

    [abandonAppointment.pending.type]: (state: AppointmentsState) => {
      state.abandonAppointmentStatus = StatusEnum.Pending;
    },
    [abandonAppointment.fulfilled.type]: (state: AppointmentsState) => {
      state.abandonAppointmentStatus = StatusEnum.Fulfilled;
    },
    [abandonAppointment.rejected.type]: (state: AppointmentsState) => {
      state.abandonAppointmentStatus = StatusEnum.Rejected;
    },

    [rescheduleAppointment.pending.type]: (state: AppointmentsState) => {
      state.rescheduleAppointmentStatus = StatusEnum.Pending;
    },
    [rescheduleAppointment.fulfilled.type]: (
      state: AppointmentsState,
      action: PayloadAction<ConfirmAppointmentResultType>,
    ) => {
      if (appointmentResultHasError(action.payload.result)) {
        state.scheduleError = action.payload.result;
        state.rescheduleAppointmentStatus = StatusEnum.Uninitialized;

        return state;
      }

      state.rescheduleAppointmentStatus = StatusEnum.Fulfilled;
    },
    [rescheduleAppointment.rejected.type]: (state: AppointmentsState) => {
      state.rescheduleAppointmentStatus = StatusEnum.Rejected;
    },

    [checkVirtualProviders.pending.type]: (state: AppointmentsState, action) => {
      state.isVirtualProvidersAvailableStatus = StatusEnum.Pending;
      state.isVirtualProvidersAvailableParams = action.meta.arg;
    },
    [checkVirtualProviders.fulfilled.type]: (state: AppointmentsState, action) => {
      if (isEqual(action.meta.arg, state.isVirtualProvidersAvailableParams)) {
        state.isVirtualProvidersAvailableStatus = StatusEnum.Fulfilled;
        state.isVirtualProvidersAvailable = action.payload;
      }
    },
    [checkVirtualProviders.rejected.type]: (state: AppointmentsState, action) => {
      if (isEqual(action.meta.arg, state.isVirtualProvidersAvailableParams)) {
        state.isVirtualProvidersAvailableStatus = StatusEnum.Rejected;
      }
    },

    [getCustomTile.pending.type]: (state: AppointmentsState) => {
      state.getCustomTileStatus = StatusEnum.Pending;
    },
    [getCustomTile.fulfilled.type]: (state: AppointmentsState, action) => {
      state.customTile = action.payload;
      state.getCustomTileStatus = StatusEnum.Fulfilled;
    },
    [getCustomTile.rejected.type]: (state: AppointmentsState) => {
      state.getCustomTileStatus = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});
export const {
  setFilter,
  clearOpenAppointmentsStatus,
  setAddress,
  setProvider,
  resetDuplicateAppointments,
  setSidebarMapOpen,
  setSidebarMapClose,
  setConfirmationData,
  reset,
  clearOpenAppointments,
  clearOpenAppointmentsLoadedDates,
  setReschedulingAppointment,
  setAbandonmentOpen,
  resetVisitReasonCategories,
  setCalendarStartDate,
  setScheduleError,
  cancelOpenAppointments,
  resetCancellation,
  resetScheduled,
  resetRescheduling,
  resetChooseTimeChecks,
  setBhReasonOpen,
  setTimezoneModalOpen,
  setFilterAppointmentsBy,
} = appointmentsSlice.actions;

export const clearProviderFilter = () =>
  setFilter({
    [FilterNames.Provider]: [],
    [FilterNames.HealthCenter]: [],
    [FilterNames.ProviderSearchValue]: null,
  });

export const appointmentsReducer = appointmentsSlice.reducer;
